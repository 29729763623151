import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { RouteConstants } from "./Constants";

import Layout from "../containers/Layouts/Layout/Layout.component";
import LoadingIndicator from "../components/common/Loading";

import LettersComponent from "../containers/Letter/Letters.component";
import {
  checkAuthorization,
  getUserData,
  isLoggedIn,
} from "../utilities/utilities";
import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import Communication from "../components/Communication";

const ProjectListComponent = lazy(
  () => import("../containers/Project/Project")
);

const ProjectComponent = lazy(
  () => import("../containers/ProjectMenu/ProjectMenu")
);

const UserComponent = lazy(
  () => import("../containers/UserManagement/UserManagement")
);

const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const SignupComponent = lazy(() => import("../components/Signup/index"));

const TaskComponent = lazy(() => import("../containers/Task/Task.component"));

// import TestComponent from "../test";

// Re-Initialize the onbeforeunload event listener

const Index = () => {
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = isLoggedIn();
    let location = useLocation();
    let params = useParams();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkAuthorization(location.pathname);
    }
    console.log({ location, isAuthenticated, isAuthorized, params });
    if ((isAuthenticated && isAuthorized) || getUserData().is_super_user)
      return children;
    return (
      <Navigate to={RouteConstants.LOGIN} state={{ from: location }} replace />
    );
  };

  return (
    <Routes>
      <Route
        path={RouteConstants.LOGIN}
        element={
          <ErrorBoundary>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.SIGN_UP}
        element={
          <ErrorBoundary>
            <Suspense fallback={<LoadingIndicator />}>
              <SignupComponent />
            </Suspense>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.TASK}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <TaskComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.LETTER}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <LettersComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path={RouteConstants.COMMUNICATION}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <Communication />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.PROJECT}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <ProjectComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.PROJECT_MENU}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <ProjectComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.PROJECT_TAB}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <ProjectComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.PROJECT_LIST}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <ProjectListComponent key="1" />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path={RouteConstants.PROJECTS}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <ProjectListComponent key="2" />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />
      <Route
        path={RouteConstants.USER}
        element={
          <ErrorBoundary>
            <RequireAuth>
              <Layout>
                <Suspense fallback={<LoadingIndicator />}>
                  <UserComponent />{" "}
                </Suspense>
              </Layout>
            </RequireAuth>
          </ErrorBoundary>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <EnterpriseLoginComponent />
          </Suspense>
        }
      />
    </Routes>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Index);
