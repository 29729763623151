import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type CheckListForm = {
  id: number;
  user_id: number;

  name: string;
  module: string;

  user: User;
  check_list_form_items: CheckListFormItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CheckListFormItem = {
  id: number;
  check_list_form_id: number;
  parent_id: number;

  description: string;
  is_subtitle: boolean;
  is_numbered: boolean;

  check_list_form_items: CheckListFormItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CheckListFormStateTypes = {
  fetchOne: ApiCallState<CheckListForm | {}>;

  fetchAllStructural: ApiCallState<CheckListForm[]>;
  fetchAllArchitecture: ApiCallState<CheckListForm[]>;
  fetchAllPlumbing: ApiCallState<CheckListForm[]>;
  fetchAllMechanical: ApiCallState<CheckListForm[]>;
  fetchAllElectrical: ApiCallState<CheckListForm[]>;
  fetchAllFireFighting: ApiCallState<CheckListForm[]>;
  fetchAllSpecialSystem: ApiCallState<CheckListForm[]>;
  fetchAllSanitary: ApiCallState<CheckListForm[]>;
};

export const CheckListFormActionTypes = {
  FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM: "FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM",
  FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_RESET",
  FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_STRUCTURAL_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM:
    "FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM",
  FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_RESET",
  FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_ARCHITECTURE_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_PLUMBING_CHECK_LIST_FORM: "FETCH_ALL_PLUMBING_CHECK_LIST_FORM",
  FETCH_ALL_PLUMBING_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_PLUMBING_CHECK_LIST_FORM_RESET",
  FETCH_ALL_PLUMBING_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_PLUMBING_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_PLUMBING_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_PLUMBING_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_MECHANICAL_CHECK_LIST_FORM: "FETCH_ALL_MECHANICAL_CHECK_LIST_FORM",
  FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_RESET",
  FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_MECHANICAL_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM: "FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM",
  FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_RESET",
  FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_ELECTRICAL_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM:
    "FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM",
  FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_RESET",
  FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_FIRE_FIGHTING_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM:
    "FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM",
  FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_RESET",
  FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_SPECIAL_SYSTEM_CHECK_LIST_FORM_SUCCESS",

  FETCH_ALL_SANITARY_CHECK_LIST_FORM: "FETCH_ALL_SANITARY_CHECK_LIST_FORM",
  FETCH_ALL_SANITARY_CHECK_LIST_FORM_RESET:
    "FETCH_ALL_SANITARY_CHECK_LIST_FORM_RESET",
  FETCH_ALL_SANITARY_CHECK_LIST_FORM_FAILURE:
    "FETCH_ALL_SANITARY_CHECK_LIST_FORM_FAILURE",
  FETCH_ALL_SANITARY_CHECK_LIST_FORM_SUCCESS:
    "FETCH_ALL_SANITARY_CHECK_LIST_FORM_SUCCESS",

  FETCH_ONE_CHECK_LIST_FORM: "FETCH_ONE_CHECK_LIST_FORM",
  FETCH_ONE_CHECK_LIST_FORM_RESET: "FETCH_ONE_CHECK_LIST_FORM_RESET",
  FETCH_ONE_CHECK_LIST_FORM_FAILURE: "FETCH_ONE_CHECK_LIST_FORM_FAILURE",
  FETCH_ONE_CHECK_LIST_FORM_SUCCESS: "FETCH_ONE_CHECK_LIST_FORM_SUCCESS",
};
