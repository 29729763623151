import { ApiCallState } from "../Utils";

export type Boq = {
  key: number | null;
  project_id: any;
  contract_id: number;
  sub_category_id: number;
  id: any;
  description: string;
  remark: string | null;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  total: number;
  sheet_name: string;
};

export type BoqStateTypes = {
  fetchAll: ApiCallState<Boq[]>;
  fetchOne: ApiCallState<Boq | {}>;
  fetchSheetName: ApiCallState<String[]>;
  fetchTotal: ApiCallState<{ amount: number }>;
};

export const BoqActionTypes = {
  FETCH_ALL_BOQ: "FETCH_ALL_BOQ",
  FETCH_ALL_BOQ_RESET: "FETCH_ALL_BOQ_RESET",
  FETCH_ALL_BOQ_FAILURE: "FETCH_ALL_BOQ_FAILURE",
  FETCH_ALL_BOQ_SUCCESS: "FETCH_ALL_BOQ_SUCCESS",

  FETCH_ONE_BOQ: "FETCH_ONE_BOQ",
  FETCH_ONE_BOQ_RESET: "FETCH_ONE_BOQ_RESET",
  FETCH_ONE_BOQ_FAILURE: "FETCH_ONE_BOQ_FAILURE",
  FETCH_ONE_BOQ_SUCCESS: "FETCH_ONE_BOQ_SUCCESS",

  FETCH_SHEET_NAME: "FETCH_SHEET_NAME",
  FETCH_SHEET_NAME_RESET: "FETCH_SHEET_NAME_RESET",
  FETCH_SHEET_NAME_FAILURE: "FETCH_SHEET_NAME_FAILURE",
  FETCH_SHEET_NAME_SUCCESS: "FETCH_SHEET_NAME_SUCCESS",

  FETCH_BOQ_TOTAL: "FETCH_BOQ_TOTAL",
  FETCH_BOQ_TOTAL_RESET: "FETCH_BOQ_TOTAL_RESET",
  FETCH_BOQ_TOTAL_FAILURE: "FETCH_BOQ_TOTAL_FAILURE",
  FETCH_BOQ_TOTAL_SUCCESS: "FETCH_BOQ_TOTAL_SUCCESS",
};
