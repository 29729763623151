import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllBoqs,
  watcherFetchBoqTotal,
  watcherFetchOneBoqs,
  watcherFetchSheetNames,
} from "./Boq/Boq.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchMaterials } from "./Material/Material.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
} from "./Project/Project.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import {
  watcherFetchAllVariation,
  watcherFetchBoqVariation,
  watcherFetchPIDVariation,
} from "./Variation/Variation.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import {
  watcherFetchAllUser,
  watcherFetchOneUser,
  watcherFetchLoggedInUser,
} from "./User/User.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import {
  watcherFetchAllMeetings,
  watcherFetchOneMeetings,
} from "./Meeting/Meeting.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";

import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";

import {
  watcherFetchAllInspectionForm,
  watcherFetchOneInspectionForm,
} from "./InspectionForm/InspectionForm.saga";
import {
  watcherFetchAllInspection,
  watcherFetchOneInspection,
} from "./Inspection/Inspection.saga";
import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";
import {
  watcherFetchAllSubmittals,
  watcherFetchOneSubmittals,
} from "./Submittal/Submittal.saga";
import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import { watcherFetchAllWeekReport } from "./WeekReport/WeekReport.saga";
import {
  watcherFetchAllQueries,
  watcherFetchOneQueries,
} from "./Query/Query.saga";
import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllRFIs } from "./RFI/RFI.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";
import { watcherFetchAllSharedDocuments } from "./SharedDocument/SharedDocument.saga";

import {
  watcherFetchAllLetters,
  watcherFetchOneLetters,
} from "./Letter/Letter.saga";

import {
  watcherFetchAllRiskLogs,
  watcherFetchOneRiskLogs,
} from "./RiskLog/RiskLog.saga";
import {
  watcherFetchAllStaffWorks,
  watcherFetchOneStaffWorks,
} from "./StaffWork/StaffWork.saga";

import {
  watcherFetchAllPaymentFiles,
  watcherFetchOnePaymentFiles,
} from "./PaymentFile/PaymentFile.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllVariationFiles,
  watcherFetchOneVariationFiles,
} from "./VariationFile/VariationFile.saga";
import {
  watcherFetchAllPriceEscalationFiles,
  watcherFetchOnePriceEscalationFiles,
} from "./PriceEscalationFile/PriceEscalationFile.saga";

import {
  watcherFetchAllSiteBooks,
  watcherFetchOneSiteBooks,
} from "./SiteBook/SiteBook.saga";
import {
  watcherFetchAllMonthlyReports,
  watcherFetchOneMonthlyReports,
} from "./MonthlyReport/MonthlyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import {
  watcherFetchAllMemos,
  watcherFetchCountMemos,
  watcherFetchOneMemos,
} from "./Memo/Memo.saga";
import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";

import {
  watcherFetchAllMaterialRequests,
  watcherFetchOneMaterialRequests,
} from "./MaterialRequest/MaterialRequest.saga";

import {
  watcherFetchAllMaterialEvaluations,
  watcherFetchOneMaterialEvaluations,
} from "./MaterialEvaluation/MaterialEvaluation.saga";

import { watcherFetchAllTasks, watcherFetchOneTasks } from "./Task/Task.saga";

import {
  watcherFetchAllStructuralCheckListForms,
  watcherFetchAllArchitectureCheckListForms,
  watcherFetchAllElectricalCheckListForms,
  watcherFetchAllFireFightingCheckListForms,
  watcherFetchAllMechanicalCheckListForms,
  watcherFetchAllPlumbingCheckListForms,
  watcherFetchAllSpecialSystemCheckListForms,
  watcherFetchOneCheckListForms,
  watcherFetchAllSanitaryCheckListForms,
} from "./CheckListForm/CheckListForm.saga";

import {
  watcherFetchAllStructuralCheckLists,
  watcherFetchAllArchitectureCheckLists,
  watcherFetchAllElectricalCheckLists,
  watcherFetchAllFireFightingCheckLists,
  watcherFetchAllMechanicalCheckLists,
  watcherFetchAllPlumbingCheckLists,
  watcherFetchAllSpecialSystemCheckLists,
  watcherFetchOneCheckLists,
  watcherFetchAllSanitaryCheckLists,
} from "./CheckList/CheckList.saga";

import {
  watcherFetchAllArchitectureFileStorages,
  watcherFetchAllElectricalFileStorages,
  watcherFetchAllFireFightingFileStorages,
  watcherFetchAllMechanicalFileStorages,
  watcherFetchAllPlumbingFileStorages,
  watcherFetchAllSpecialSystemFileStorages,
  watcherFetchAllStructuralFileStorages,
  watcherFetchAllSanitaryFileStorages,
  watcherFetchOneFileStorages,
} from "./FileStorage/FileStorage.saga";

import {
  watcherFetchAllWorkPermits,
  watcherFetchOneWorkPermits,
} from "./WorkPermit/WorkPermit.saga";

import {
  watcherFetchAllPaymentRequests,
  watcherFetchOnePaymentRequests,
} from "./PaymentRequest/PaymentRequest.saga";

import {
  watcherFetchAllPaymentss,
  watcherFetchOnePaymentss,
} from "./Payments/Payments.saga";

import { watcherFetchAllDatas, watcherFetchOneDatas } from "./Data/Data.saga";

import {
  watcherFetchAllContractAdminstrations,
  watcherFetchOneContractAdminstrations,
} from "./ContractAdminstration/ContractAdminstration.saga";

import {
  watcherFetchAllChecklistRemarks,
  watcherFetchOneChecklistRemarks,
} from "./ChecklistRemark/ChecklistRemark.saga";

import {
  watcherFetchAllWeeklyPlans,
  watcherFetchOneWeeklyPlans,
} from "./WeeklyPlan/WeeklyPlan.saga";

import {
  watcherFetchAllRequestForTests,
  watcherFetchOneRequestForTests,
} from "./RequestForTest/RequestForTest.saga";
import {
  watcherFetchAllSiteHandovers,
  watcherFetchOneSiteHandovers,
} from "./SiteHandover/SiteHandover.saga";

import {
  watcherFetchAllShareSiteHandovers,
  watcherFetchOneShareSiteHandovers,
} from "./ShareSiteHandover/ShareSiteHandover.saga";

import {
  watcherFetchAllShareDatas,
  watcherFetchOneShareDatas,
} from "./ShareData/ShareData.saga";

import {
  watcherFetchAllShareSubmittals,
  watcherFetchOneShareSubmittals,
} from "./ShareSubmittal/ShareSubmittal.saga";

import {
  watcherFetchAllShareInspections,
  watcherFetchOneShareInspections,
} from "./ShareInspection/ShareInspection.saga";

import {
  watcherFetchAllMeetingFiles,
  watcherFetchOneMeetingFiles,
} from "./MeetingFile/MeetingFile.saga";

import {
  watcherFetchAllShareMeetingFiles,
  watcherFetchOneShareMeetingFiles,
} from "./ShareMeetingFile/ShareMeetingFile.saga";
import { watcherFetchAllRoles, watcherFetchOneRoles } from "./Role/Role.saga";
import {
  watcherFetchAllCommunications,
  watcherFetchOneCommunications,
} from "./Communication/Communication.saga";

export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchMaterials),
    call(watcherFetchAllUsage),
    call(watcherFetchAllBoqs),
    call(watcherFetchBoqTotal),
    call(watcherFetchOneBoqs),
    call(watcherFetchSheetNames),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllVariation),
    call(watcherFetchBoqVariation),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllSchedules),
    call(watcherFetchOneUser),
    call(watcherFetchAllClients),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllMeetings),
    call(watcherFetchOneMeetings),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),

    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchPIDVariation),
    call(watcherFetchAllInspectionForm),
    call(watcherFetchOneInspectionForm),
    call(watcherFetchAllInspection),
    call(watcherFetchOneInspection),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllSubmittals),
    call(watcherFetchOneSubmittals),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchAllQueries),
    call(watcherFetchOneQueries),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllRFIs),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllApplications),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllLetters),
    call(watcherFetchOneLetters),
    call(watcherFetchAllRiskLogs),
    call(watcherFetchOneRiskLogs),
    call(watcherFetchAllStaffWorks),
    call(watcherFetchOneStaffWorks),

    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllPaymentFiles),
    call(watcherFetchOnePaymentFiles),
    call(watcherFetchAllVariationFiles),
    call(watcherFetchOneVariationFiles),
    call(watcherFetchAllPriceEscalationFiles),
    call(watcherFetchOnePriceEscalationFiles),

    call(watcherFetchAllSiteBooks),
    call(watcherFetchOneSiteBooks),
    call(watcherFetchAllMonthlyReports),
    call(watcherFetchOneMonthlyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllMemos),
    call(watcherFetchOneMemos),
    call(watcherFetchCountMemos),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchLoggedInUser),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllMaterialRequests),
    call(watcherFetchOneMaterialRequests),
    call(watcherFetchAllMaterialEvaluations),
    call(watcherFetchOneMaterialEvaluations),
    call(watcherFetchAllTasks),
    call(watcherFetchOneTasks),
    call(watcherFetchAllStructuralCheckListForms),
    call(watcherFetchAllArchitectureCheckListForms),
    call(watcherFetchAllElectricalCheckListForms),
    call(watcherFetchAllFireFightingCheckListForms),
    call(watcherFetchAllMechanicalCheckListForms),
    call(watcherFetchAllPlumbingCheckListForms),
    call(watcherFetchAllSpecialSystemCheckListForms),
    call(watcherFetchOneCheckListForms),
    call(watcherFetchAllSanitaryCheckListForms),
    call(watcherFetchAllStructuralCheckLists),
    call(watcherFetchAllArchitectureCheckLists),
    call(watcherFetchAllElectricalCheckLists),
    call(watcherFetchAllFireFightingCheckLists),
    call(watcherFetchAllMechanicalCheckLists),
    call(watcherFetchAllPlumbingCheckLists),
    call(watcherFetchAllSpecialSystemCheckLists),
    call(watcherFetchAllSanitaryCheckLists),
    call(watcherFetchOneCheckLists),
    call(watcherFetchAllArchitectureFileStorages),
    call(watcherFetchAllElectricalFileStorages),
    call(watcherFetchAllFireFightingFileStorages),
    call(watcherFetchAllMechanicalFileStorages),
    call(watcherFetchAllPlumbingFileStorages),
    call(watcherFetchAllSpecialSystemFileStorages),
    call(watcherFetchAllSanitaryFileStorages),
    call(watcherFetchAllStructuralFileStorages),
    call(watcherFetchOneFileStorages),
    call(watcherFetchAllWorkPermits),
    call(watcherFetchOneWorkPermits),
    call(watcherFetchAllPaymentRequests),
    call(watcherFetchOnePaymentRequests),
    call(watcherFetchAllPaymentss),
    call(watcherFetchOnePaymentss),
    call(watcherFetchAllDatas),
    call(watcherFetchOneDatas),
    call(watcherFetchAllContractAdminstrations),
    call(watcherFetchOneContractAdminstrations),
    call(watcherFetchAllChecklistRemarks),
    call(watcherFetchOneChecklistRemarks),
    call(watcherFetchAllWeeklyPlans),
    call(watcherFetchOneWeeklyPlans),
    call(watcherFetchAllRequestForTests),
    call(watcherFetchOneRequestForTests),
    call(watcherFetchAllSiteHandovers),
    call(watcherFetchOneSiteHandovers),
    call(watcherFetchAllShareSiteHandovers),
    call(watcherFetchOneShareSiteHandovers),
    call(watcherFetchAllShareDatas),
    call(watcherFetchOneShareDatas),
    call(watcherFetchAllShareSubmittals),
    call(watcherFetchOneShareSubmittals),
    call(watcherFetchAllShareInspections),
    call(watcherFetchOneShareInspections),
    call(watcherFetchAllMeetingFiles),
    call(watcherFetchOneMeetingFiles),
    call(watcherFetchAllShareMeetingFiles),
    call(watcherFetchOneShareMeetingFiles),
    call(watcherFetchOneRoles),
    call(watcherFetchAllRoles),
    call(watcherFetchAllCommunications),
    call(watcherFetchOneCommunications),
  ]);
}
