import {
  CommunicationStateTypes,
  CommunicationActionTypes,
} from "./Communication.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CommunicationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const CommunicationReducer = (
  state: CommunicationStateTypes = INITIAL_STATE,
  action: any
): CommunicationStateTypes => {
  switch (action.type) {
    case CommunicationActionTypes.FETCH_ALL_COMMUNICATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CommunicationActionTypes.FETCH_ALL_COMMUNICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CommunicationActionTypes.FETCH_ALL_COMMUNICATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CommunicationActionTypes.FETCH_ALL_COMMUNICATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CommunicationActionTypes.FETCH_ONE_COMMUNICATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CommunicationActionTypes.FETCH_ONE_COMMUNICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CommunicationActionTypes.FETCH_ONE_COMMUNICATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CommunicationActionTypes.FETCH_ONE_COMMUNICATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CommunicationReducer;
