import { CommunicationActionTypes } from "./Communication.type";

/**
 * Fetch All Communication
 *
 * @param payload
 */
export const fetchAllCommunication = (payload?: any) => ({
  type: CommunicationActionTypes.FETCH_ALL_COMMUNICATION,
  payload: payload,
});

/**
 * Fetch All Communication
 *
 * @param payload
 */
export const fetchOneCommunication = (payload?: any) => ({
  type: CommunicationActionTypes.FETCH_ONE_COMMUNICATION,
  payload: payload,
});

/**
 * Reset Fetch Communication State
 *
 * @param payload
 */
export const fetchAllCommunicationReset = (payload?: any) => ({
  type: CommunicationActionTypes.FETCH_ALL_COMMUNICATION_RESET,
  payload: payload,
});
