import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CommunicationActionTypes } from "./Communication.type";

export function* fetchAllCommunications(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/communication?${query}`);
    yield put({
      type: CommunicationActionTypes.FETCH_ALL_COMMUNICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CommunicationActionTypes.FETCH_ALL_COMMUNICATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCommunications(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/communication/${action.payload}`
    );
    yield put({
      type: CommunicationActionTypes.FETCH_ONE_COMMUNICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CommunicationActionTypes.FETCH_ONE_COMMUNICATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCommunications() {
  yield takeLatest(
    CommunicationActionTypes.FETCH_ALL_COMMUNICATION,
    fetchAllCommunications
  );
}

export function* watcherFetchOneCommunications() {
  yield takeLatest(
    CommunicationActionTypes.FETCH_ONE_COMMUNICATION,
    fetchOneCommunications
  );
}
