import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import BoqReducer from "./Boq/Boq.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import CustomerReducer from "./Customer/Customer.reducer";
import DocumentReducer from "./Document/Document.reducer";
import InvoiceReducer from "./Invoice/Invoice.reducer";
import MaterialReducer from "./Material/Material.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import ServiceReducer from "./Service/Service.reducer";
import UsageReducer from "./Usage/Usage.reducer";
import UserReducer from "./User/User.reducer";
import VariationReducer from "./Variation/Variation.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import EmployeeAccommodationPlanReducer from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import InspectionFormReducer from "./InspectionForm/InspectionForm.reducer";
import InspectionReducer from "./Inspection/Inspection.reducer";
import CastingReducer from "./Casting/Casting.reducer";
import TestResultReducer from "./TestResult/TestResult.reducer";
import TestRequestReducer from "./TestRequest/TestRequest.reducer";
import SubmittalReducer from "./Submittal/Submittal.reducer";
import MaterialRequestApprovalReducer from "./MaterialRequestApproval/MaterialRequestApproval.reducer";
import SiteDiaryReducer from "./SiteDiary/SiteDiary.reducer";
import WeekReportReducer from "./WeekReport/WeekReport.reducer";
import MeetingReducer from "./Meeting/Meeting.reducer";
import QueryReducer from "./Query/Query.reducer";
import MasterScheduleReducer from "./MasterSchedule/MasterSchedule.reducer";
import RFIReducer from "./RFI/RFI.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import ApplicationReducer from "./Application/Application.reducer";
import MediaReducer from "./Media/Media.reducer";
import SHEReducer from "./SHE/SHE.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import LetterReducer from "./Letter/Letter.reducer";
import RiskLogReducer from "./RiskLog/RiskLog.reducer";
import StaffWorkReducer from "./StaffWork/StaffWork.reducer";
import PaymentFileReducer from "./PaymentFile/PaymentFile.reducer";

import TestEvaluationReducer from "./TestEvaluation/TestEvaluation.reducer";
import VariationFileReducer from "./VariationFile/VariationFile.reducer";
import PriceEscalationFileReducer from "./PriceEscalationFile/PriceEscalationFile.reducer";
import SiteBookReducer from "./SiteBook/SiteBook.reducer";
import MonthlyReportReducer from "./MonthlyReport/MonthlyReport.reducer";
import MaterialUsageReducer from "./MaterialUsage/MaterialUsage.reducer";
import MemoReducer from "./Memo/Memo.reducer";
import EstimatedBuildingCostReducer from "./EstimatedBuildingCost/EstimatedBuildingCost.reducer";
import CurrencyReducer from "./Currency/Currency.reducer";
import MaterialRequestReducer from "./MaterialRequest/MaterialRequest.reducer";
import MaterialEvaluationReducer from "./MaterialEvaluation/MaterialEvaluation.reducer";
import TaskReducer from "./Task/Task.reducer";
import CheckListFormReducer from "./CheckListForm/CheckListForm.reducer";
import CheckListReducer from "./CheckList/CheckList.reducer";
import FileStorageReducer from "./FileStorage/FileStorage.reducer";
import WorkPermitReducer from "./WorkPermit/WorkPermit.reducer";
import PaymentRequestReducer from "./PaymentRequest/PaymentRequest.reducer";
import PaymentsReducer from "./Payments/Payments.reducer";
import DataReducer from "./Data/Data.reducer";
import ContractAdminstrationReducer from "./ContractAdminstration/ContractAdminstration.reducer";
import ChecklistRemarkReducer from "./ChecklistRemark/ChecklistRemark.reducer";
import WeeklyPlanReducer from "./WeeklyPlan/WeeklyPlan.reducer";
import RequestForTestReducer from "./RequestForTest/RequestForTest.reducer";
import SiteHandoverReducer from "./SiteHandover/SiteHandover.reducer";
import ShareSiteHandoverReducer from "./ShareSiteHandover/ShareSiteHandover.reducer";
import ShareDataReducer from "./ShareData/ShareData.reducer";
import ShareSubmittalReducer from "./ShareSubmittal/ShareSubmittal.reducer";
import ShareInspectionReducer from "./ShareInspection/ShareInspection.reducer";
import MeetingFileReducer from "./MeetingFile/MeetingFile.reducer";
import ShareMeetingFileReducer from "./ShareMeetingFile/ShareMeetingFile.reducer";
import RoleReducer from "./Role/Role.reducer";
import CommunicationReducer from "./Communication/Communication.reducer";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  boq: BoqReducer,
  material: MaterialReducer,
  usage: UsageReducer,
  customer: CustomerReducer,
  service: ServiceReducer,
  variation: VariationReducer,
  invoice: InvoiceReducer,
  document: DocumentReducer,
  schedule: ScheduleReducer,
  user: UserReducer,
  employee_accommodation_plan: EmployeeAccommodationPlanReducer,
  client: ClientReducer,
  log: LogReducer,
  resource: ResourceReducer,
  inspection_form: InspectionFormReducer,
  inspection: InspectionReducer,
  casting: CastingReducer,
  submittal: SubmittalReducer,
  material_request_approval: MaterialRequestApprovalReducer,
  test_result: TestResultReducer,
  test_request: TestRequestReducer,
  meeting: MeetingReducer,
  site_diary: SiteDiaryReducer,
  week_report: WeekReportReducer,
  query: QueryReducer,
  master_schedule: MasterScheduleReducer,
  rfi: RFIReducer,
  review_form: ReviewFormReducer,
  applications: ApplicationReducer,
  media: MediaReducer,
  she: SHEReducer,
  sharedDocument: SharedDocumentReducer,
  letter: LetterReducer,
  risk_log: RiskLogReducer,
  staff_work: StaffWorkReducer,
  test_evaluation: TestEvaluationReducer,
  payment_file: PaymentFileReducer,
  variation_file: VariationFileReducer,
  price_escalation_file: PriceEscalationFileReducer,

  site_book: SiteBookReducer,
  monthly_report: MonthlyReportReducer,
  material_usage: MaterialUsageReducer,
  memo: MemoReducer,
  estimated_building_cost: EstimatedBuildingCostReducer,

  currency: CurrencyReducer,
  material_request: MaterialRequestReducer,
  material_evaluation: MaterialEvaluationReducer,
  task: TaskReducer,
  checklist_form: CheckListFormReducer,
  checklist: CheckListReducer,
  file_storage: FileStorageReducer,
  work_permit: WorkPermitReducer,
  payment_request: PaymentRequestReducer,
  payments: PaymentsReducer,
  data: DataReducer,
  contract_adminstration: ContractAdminstrationReducer,
  checklist_remark: ChecklistRemarkReducer,
  weekly_plan: WeeklyPlanReducer,
  request_for_test: RequestForTestReducer,
  site_handover: SiteHandoverReducer,
  share_site_handover: ShareSiteHandoverReducer,
  share_data: ShareDataReducer,
  share_submittal: ShareSubmittalReducer,
  share_inspection: ShareInspectionReducer,
  meeting_file: MeetingFileReducer,
  share_meeting_file: ShareMeetingFileReducer,
  role: RoleReducer,
  communication: CommunicationReducer,
});

export default persistReducer(PersistConfig, RootReducer);
