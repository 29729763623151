import { Button, Form, Modal, Popconfirm, Select, Table } from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ShareAltOutlined } from "@ant-design/icons";
import { getUsers, SharePropType } from "./Share.util";
import { getUserData, searchProp } from "../../../utilities/utilities";
import { toNumber } from "lodash";
import { fetchAllUser } from "../../../redux/User/User.action";
const ShareComponent: FC<SharePropType> = ({
  loading,
  onShare,
  payload,
  users,
  onRemove,
  fetchUsers,
  filter,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      if (filter) {
        fetchUsers(filter);
      }
    }
  }, [isModalVisible, filter, fetchUsers]);

  const [form] = Form.useForm();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        className="pl-0"
        type="text"
        icon={<ShareAltOutlined />}
        onClick={() => setIsModalVisible(true)}
      >
        Share
      </Button>
      <Modal
        className="fixed-modal"
        centered
        title="Share"
        width={800}
        visible={isModalVisible}
        onCancel={handleOk}
        footer={[
          <>
            <Button className="btn-outline" htmlType="reset" onClick={handleOk}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => form.submit()}
            >
              Share
            </Button>
          </>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={(values) => onShare(values)}
          form={form}
          initialValues={{ type: "View" }}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                label="User"
                name="user_id"
                rules={[{ required: true, message: "User is Required" }]}
              >
                <Select
                  placeholder="name"
                  {...searchProp}
                  onChange={(e) => {
                    form.setFieldsValue({ type: "View" });
                  }}
                >
                  {getUsers(users.payload, payload).map((user, index) => (
                    <Select.Option value={user.id} key={index}>
                      {user.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table
                dataSource={payload}
                pagination={false}
                columns={[
                  {
                    title: "No",
                    key: "no",
                    render: (value, record, index) => index + 1,
                  },
                  {
                    title: "Name",
                    key: "user",
                    render: (value, record) =>
                      record.assigned_to_user.full_name,
                  },

                  {
                    title: "Shared By",
                    key: "assigned_by",
                    render: (value, record) =>
                      record.assigned_by_user.full_name,
                  },
                  {
                    title: "Action",
                    key: "action",
                    render: (value, record) =>
                      record.assigned_by === toNumber(getUserData().id) ||
                      getUserData().is_super_user ? (
                        <Popconfirm
                          placement="leftTop"
                          title="Are you sure you want to remove this Assigned User?"
                          onConfirm={() => onRemove(record.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button danger type="text">
                            Delete
                          </Button>
                        </Popconfirm>
                      ) : null,
                  },
                ]}
              />
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  users: state.user.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (action: any) => dispatch(fetchAllUser(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareComponent);
