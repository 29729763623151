import axios from "axios";
import { Communication } from "../../../redux/Communication/Communication.type";
import { Project } from "../../../redux/Project/Project.type";
import { ApiCallState } from "../../../redux/Utils";
import { API_BASE_URI } from "../../../redux/ApiCall";
import { uniq } from "lodash";
import { User } from "../../../redux/User/User.type";
import { getUserData } from "../../../utilities/utilities";
import moment from "moment";

export type CommunicationPropType = {
  communications: ApiCallState<Communication[]>;
  fetchCommunications: Function;
  projects: ApiCallState<Project[]>;
  fetchProjects: Function;
  fetchUsers: Function;
};

export const parseData = (remark: Communication, user_id: number) => {
  let counter = 0;
  let user = remark.communication_users?.find((e: any) => e.id === user_id);
  if (user) {
    let last_seen = user?.["communication_user"].last_seen;
    remark.communication_remarks.forEach((e) => {
      if (!moment(last_seen).isSameOrAfter(moment(e.createdAt), "minute"))
        counter += 1;
    });
  } else {
    remark.communication_remarks.forEach((e) => {
      counter += 1;
    });
  }
  return { counter };
};

export type RemarkPropType = {
  record: Communication;
  users: ApiCallState<User[]>;
  fetchCommunications: Function;
};

export type AddCommunicationPropType = {
  communication?: Communication;
  fetchCommunications: Function;
  projectAction: [any, React.Dispatch<React.SetStateAction<any>>];
  projects: ApiCallState<Project[]>;
  communications: ApiCallState<Communication[]>;
  users: ApiCallState<User[]>;
};

export type ViewCommunicationPropType = {
  communication: Communication;
  communications: ApiCallState<Communication[]>;
};

export type ViewAttachmentPropType = {
  communication: Communication;
};

export const DELETE = (id: number) =>
  axios.delete(`${API_BASE_URI}/communication/${id}`);

export const POST = (data: any) =>
  axios.post(API_BASE_URI + "/communication", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const POST_REMARK = (data: any) =>
  axios.post(API_BASE_URI + "/communication-remark", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const PUT = (data: any) =>
  axios.post(API_BASE_URI + "/communication/seen", data);

export const PUT_STATUS = (data: any) =>
  axios.put(API_BASE_URI + "/communication-status", data);

export const DELETE_STATUS = (id: number) =>
  axios.delete(API_BASE_URI + `/communication-status/${id}`);

export const POST_STATUS = (data: any) =>
  axios.post(API_BASE_URI + "/communication-status", data);

export const getCompany = (
  project_id: number,
  projects: Project[],
  users: User[],
  show_all: boolean = false
) => {
  let project = projects.find((e) => e.id === project_id);
  if (show_all)
    return uniq(users.filter((e) => e.is_super_user).map((e) => e.company));
  else
    return project
      ? uniq(
          [
            ...project.users.map((e) => e.company),
            ...users.filter((e) => e.is_super_user).map((e) => e.company),
          ].filter((e) => e !== getUserData().company)
        )
      : [];
};
