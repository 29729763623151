import { User } from "../../../redux/User/User.type";
import { ApiCallState } from "../../../redux/Utils";
import { getUserData } from "../../../utilities/utilities";

export type SharePropType = {
  onShare: Function;
  onRemove: Function;
  filter?: any;
  payload: {
    id: number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    assigned_by_user: User;
    assigned_to_user: User;
  }[];
  loading: boolean;
  users: ApiCallState<User[]>;
  fetchUsers: Function;
};

export const getUsers = (
  users: User[],
  selected: {
    id: number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
  }[]
) => {
  return users.filter(
    (user) =>
      getUserData().id !== user.id &&
      !selected.find((e) => e.user_id === user.id)
  );
};
